<template>
  <div class="flex items-center justify-center">
    <div class="sm:w-max-2xl w-full">
      <div class="flex flex-row justify-between items-center mb-8">
        <span class="text-2xl font-bold"
          >{{ !isUpdating ? "Create" : "Update" }} A Plan</span
        >
        <div class="flex">
          <vs-button
            v-if="isUpdating"
            color="danger"
            @click="activeDialog = !activeDialog"
          >
            Delete
          </vs-button>
          <vs-button to="/plans" class="ml-2"> Return</vs-button>
        </div>
      </div>

      <div class="flex flex-col gap-4">
        <vs-select
          placeholder="Select"
          v-model="selectedProduct"
          v-if="products"
          block
        >
          <vs-option
            v-for="product in products"
            :label="product.name"
            :value="product._id"
            :key="product._id"
          >
            {{ product.name }}
          </vs-option>
        </vs-select>

        <div class="flex flex-row items-center gap-4">
          <vs-input
            type="number"
            placeholder="Plan Duration (in seconds)"
            v-model="plan.duration"
            block
          />
          <span class="whitespace-nowrap">{{ timestampToDuration }}</span>
        </div>

        <div class="flex">
          <vs-button
            v-for="(value, key) in exampleDurations"
            :key="key"
            @click="plan.duration = key"
          >
            {{ value }}
          </vs-button>
        </div>

        <vs-input
          type="number"
          placeholder="Product Buy Repeat Limit"
          v-model="plan.buyRepeatLimit"
          block
        >
          <template #message-danger>
            <span class="text-xs"
              >Unlimited if value entered is 0 (number of repeat
              purchases)</span
            >
          </template>
        </vs-input>

        <vs-input
          type="number"
          placeholder="Plan Price"
          v-model="plan.price"
          block
        />
        <div class="flex items-center">
          <vs-input
            type="number"
            placeholder="Plan Slot"
            v-model="plan.slot"
            block
          />
          <vs-switch v-model="plan.active" class="w-1/2 ml-2">
            {{ plan.active ? "Active" : "Deactivate" }}
          </vs-switch>
        </div>
      </div>

      <div class="footer-dialog mt-4">
        <vs-button v-if="!isUpdating" @click="createPlan()" block>
          Create
        </vs-button>
        <vs-button v-else @click="updatePlan()" block> Update</vs-button>
      </div>
    </div>

    <vs-dialog width="550px" not-center v-model="activeDialog">
      <template #header>
        <h4 class="not-margin">Are you sure you want to delete this plan?</h4>
      </template>

      <template #footer>
        <div class="flex justify-end">
          <vs-button color="success" @click="deletePlan()" transparent>
            Yes
          </vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
import {
  deletePlan,
  getPlanById,
  updatePlan,
  getProductsByStoreForStore,
  getMyStore,
  createPlan,
} from "@/services/ApiService";
import humanizeDuration from "humanize-duration";

export default {
  name: "CreatePlan",
  data: () => ({
    plan: {
      _id: undefined,
      duration: undefined,
      price: undefined,
      slot: undefined,
      active: true,
      buyRepeatLimit: undefined,
    },
    selectedProduct: undefined,
    products: undefined,
    isUpdating: false,
    activeDialog: false,
    exampleDurations: {
      1800: "30 minutes",
      3600: "1 hour",
      21600: "6 hours",
      43200: "12 hours",
      86400: "1 day",
      604800: "1 week",
      2629800: "1 month",
      31557600: "1 year",
    },
  }),
  computed: {
    timestampToDuration() {
      if (!this.duration) return "";
      return humanizeDuration(this.duration * 1000);
    },
  },
  methods: {
    createPlan() {
      const planData = {
        product: this.selectedProduct,
        duration: Number(this.plan.duration),
        price: Number(this.plan.price),
        slot: Number(this.plan.slot),
        active: this.plan.active,
        buyRepeatLimit: Number(this.plan.buyRepeatLimit),
      };

      createPlan(planData)
        .then(() => {
          this.$vs.notification({
            color: "success",
            name: "Success",
            text: "Plan successfully created!",
          });

          this.$router.push("/plans");
        })
        .catch((error) => {
          this.$vs.notification({
            color: "danger",
            name: "Error",
            text: error.response.data.message,
          });
        });
    },
    updatePlan() {
      const planData = {
        product: this.selectedProduct,
        duration: Number(this.plan.duration),
        price: Number(this.plan.price),
        slot: Number(this.plan.slot),
        active: this.plan.active,
        buyRepeatLimit: Number(this.plan.buyRepeatLimit),
      };

      updatePlan(this.plan._id, planData)
        .then(() => {
          this.$vs.notification({
            color: "success",
            name: "Success",
            text: "Plan successfully updated!",
          });

          this.$router.push("/plans");
        })
        .catch((error) => {
          this.$vs.notification({
            color: "danger",
            name: "Error",
            text: error.response.data.message,
          });
        });
    },
    async myProducts() {
      const myStore = await getMyStore();
      if (myStore) {
        getProductsByStoreForStore(myStore._id)
          .then((response) => {
            this.products = response;
            if (!this.isUpdating) {
              this.selectedProduct = response[0]._id;
            }
          })
          .catch((error) => {
            this.$vs.notification({
              color: "danger",
              name: "Error",
              text: error.response.data.message,
            });
          });
      }
    },
    async getCurrentProduct() {
      const res = await getPlanById(this.$route.params.id);

      this.plan = res;
      this.selectedProduct = res.product;
      this.isUpdating = true;
    },
    deletePlan() {
      deletePlan(this.plan._id)
        .then(() => {
          this.$vs.notification({
            color: "success",
            name: "Success",
            text: "Plan successfully deleted!",
          });

          this.$router.back();
        })
        .catch((error) => {
          this.$vs.notification({
            color: "danger",
            name: "Error",
            text: error.response.data.message,
          });
        });
    },
  },
  async mounted() {
    const { id } = this.$route.params;
    if (id) {
      await this.getCurrentProduct();
    }
    await this.myProducts();
  },
};
</script>
